<template v-show="showMarkers">
  <l-feature-group :visible="visible">
    <l-marker :lat-lng="[marker.lat, marker.lng]" v-if="showMarkers">
      <l-icon class="container" :iconSize="[40, 40]" :iconAnchor="[20, 52]">
        <MapMarkerSVG :colour="marker.colour" />
        <div class="subheading centered">
          {{ marker.number }}
        </div>
      </l-icon>
      <l-popup
        :ref="`pop_${marker.id}`"
        :options="{
          className: 'sentry-popup dark',
          offset: [128, -13],
          autoClose: false,
          closeOnClick: true
        }"
      >
        <v-container>
          <!-- translate3d(626px, 427px, 0px) -->
          <v-layout>
            <span class="markerText">
              {{ marker.nickname }}
            </span>
          </v-layout>
        </v-container>
      </l-popup>
    </l-marker>
  </l-feature-group>
</template>

<script>
import { LFeatureGroup, LMarker, LIcon, LPopup } from 'vue2-leaflet'
import { mapState } from 'vuex'

const MapMarkerSVG = () => import('./MapMarkerSVG.vue')

const props = {
  visible: {
    type: Boolean,
    default: false
  },
  marker: {
    type: Object,
    default: () => ({})
  },
  activeKey: {
    type: [Number, String],
    default: ''
  }
}

export default {
  name: 'MapSiteMarkersLayer',
  props,
  data: () => ({
    markerSVG: {
      fill: 'yellow'
    }
  }),
  components: {
    LFeatureGroup,
    LMarker,
    LPopup,
    LIcon,
    MapMarkerSVG
  },
  mounted() {
    this.marker.colour
      ? (this.markerSVG.fill = this.marker.colour)
      : (this.markerSVG.fill = 'red')
  },
  computed: {
    ...mapState('site_markers', ['site_markers', 'showMarkers'])
  }
}
</script>

<style scoped>
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container {
  position: relative;
  color: white;
  width: auto !important;
  height: auto !important;
}
.marker {
  height: 40px;
  width: 40px;
}

.detection-popup .leaflet-popup-content-wrapper {
  min-width: 300px;
  background: rgba(48, 48, 48, 0.8);
  color: #fff;
  border-left: 4px solid orange;
  border-radius: 0px;
}

.detection-popup .leaflet-popup-content {
  margin: 3px 0px 0px 0px;
  width: auto !important;
}

.markerText {
  font-weight: bold;
}
</style>
